import { useEffect } from "react";
import ReactGA from "react-ga";  //Google Analytics 

// core components
import Header from "./views/common/Header/Header";
import Footer from "./views/common/Footer/Footer";
import GridContainer from "./views/common/Grid/GridContainer";
import GridItem from "./views/common/Grid/GridItem";
import HeaderLinks from "./views/common/Header/HeaderLinks";
import Parallax from "./views/common/Parallax/Parallax";

import useStyles from "./assets/styles/landingPage";

import { primaryColor, secondaryColor } from './assets/styles/common/material-kit-react';

// nodejs library that concatenates classes
// var classNames = require('classnames');
import classNames from 'classnames';
import ProductSection from './views/sections/ProductSection';
import WorkSection from './views/sections/WorkSection';
import { createMuiTheme } from '@material-ui/core';

export default function App() {

  useEffect(() => {
    ReactGA.initialize('G-4KTTY0FTD2');
    ReactGA.pageview(window.location.pathname);
  })

  const classes = useStyles();
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
  });

  return <div>
    <Header
      color="transparent"
      image="img/logo-inverted.png"
      brand="allaboutApps"
      rightLinks={<HeaderLinks />}
      fixed
      changeColorOnScroll={{
        height: 400,
        color: "white"
      }}
    />

    <Parallax image="img/landing-bg.png" style={{ backgroundColor: 'white' }}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <h1 className={classes.title}>We don't just build apps.</h1>
            <h4>
              We create engaging, holistic digital solutions crucial for creating a cohesive customer journey.<br />
                We pride ourselves in building solutions that boasts quality and welcomes scalability.
          </h4>
            {/* <br />
            <Button variant='contained' color='info' style={{
              height: '48px',
              width: '160px'
            }}>Contact us</Button> */}
          </GridItem>
        </GridContainer>
      </div>
    </Parallax>
    <div className={classNames(classes.main, classes.mainRaised)}>
      <div className={classes.container}>
        <ProductSection />
        {/* <TeamSection /> */}
      </div>
      <div style={{ backgroundColor: primaryColor }}>
        <div className={classes.container}>
          <WorkSection />
        </div>
      </div>


    </div>
    <Footer />
  </div>
}
