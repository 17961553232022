import React from "react";
// @material-ui/core components

// @material-ui/icons

// core components
import useStyles from "../../assets/styles/workStyle";
import GridContainer from "../common/Grid/GridContainer";
import GridItem from "../common/Grid/GridItem";
import CustomInput from "../common/CustomInput/CustomInput";
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Button from "../common/CustomButtons/Button";

interface WorkSectionState {
  message: String
}

class WorkSection extends React.Component<{ classes: any }, WorkSectionState> {

  constructor(props: any) {
    super(props);

    this.state = { message: '' };

    this.handleContactUsMessageChange = this.handleContactUsMessageChange.bind(this);
    this.handleSendMessageClick = this.handleSendMessageClick.bind(this);
  }

  render() {
    return <div className={this.props.classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 id='workWithUs' className={this.props.classes.title} style={{ color: 'white' }}>Work with us</h2>
          <h5 className={this.props.classes.description} style={{ color: 'white' }}>
            We look forward to hearing from you. Contact us about any further
            collaboration. We will get back to you in a couple of hours.
        </h5>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <form>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <GridContainer style={{ paddingTop: '30px' }}>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={1} sm={1} md={1}>
                        <EmailIcon />
                      </GridItem>
                      <GridItem xs={10} sm={11} md={11}>
                        <div>
                          <a href='mailto:info@allaboutapps.co.za?subject=We%27d%20like%20to%20work%20with%20you' style={{ color: 'white' }}>info@allaboutapps.co.za</a>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={1} sm={1} md={1}>
                        <LocationOnIcon />
                      </GridItem>
                      <GridItem xs={10} sm={11} md={11}>
                        <div>Work in Progress, 5th Floor,<br />
                      Woodstock Exchange,<br />
                      68 Albert Rd, Woodstock, Cape Town, 7925</div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  {/* <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Name"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Your Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem> */}

                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Your Message"
                      id="message"
                      formControlProps={{
                        fullWidth: true,
                        className: this.props.classes.textArea
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        onChange: this.handleContactUsMessageChange,
                        style: {
                          color: 'white'
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Button variant='contained' color='info' onClick={this.handleSendMessageClick}
                      style={{
                        height: '48px',
                        width: '160px'
                      }}>Send message</Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  }

  handleContactUsMessageChange(e: any) {
    this.setState({ message: e.target.value });
  }

  handleSendMessageClick(e: any) {
    //e.preventDefault();

    window.open('mailto:info@allaboutapps.co.za?subject=We%27d%20like%20to%20work%20with%20you&body=' + this.state.message);
  }
}



export default function () {
  const classes = useStyles();

  return (
    <WorkSection classes={classes} />
  )
}