import { makeStyles } from "@material-ui/core";
import { title } from "./common/material-kit-react";

export default makeStyles({
  serviceIcon: {
    margin: '0 auto'
  },
  section: {
    padding: "70px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  description: {
    color: "#999"
  }
});
